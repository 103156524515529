.pesquisa-page {
  background-image: url('../images/bussola-bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: var(--light);
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.pesquisa-page .finish-text {
  font-size: 20px;
  padding: 0px 30px;
}

.pesquisa-page .finish-text a {
  color: white;
}

.radio-question-component {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.radio-question-component h2 {
  padding: 0px 30px;
}

.radio-question-component-options {
  padding: 0px 30px;
}

.option-div {
  margin: 4px 0px;
}

.radio-question-label {
  font-size: 16px;
  padding-left: 5px;
  font-family: var(--text);
}

.short-answer-input {
  width: 100%;
}

.long-question-component {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.long-question-component h2 {
  padding: 0px 30px;
}

.long-answer-input {
  width: 85vw;
  min-height: 100px;
}

.pesquisa-button-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: center;
}

.pesquisa-button {
  margin: 15px;
}

@media (min-width: 500px) and (max-width: 600px) {
  .long-question-component textarea {
    width: 70%;
  }
}

@media (min-width: 601px) and (max-width: 800px) {
  .long-question-component textarea {
    width: 60%;
  }
}

@media (min-width: 801px) {
  .long-question-component textarea {
    width: 55%;
  }
}
