@import url('https://fonts.googleapis.com/css2?family=Libre+Franklin&family=Mate&family=Raleway&display=swap');

:root {
  --text-dark: #28142e;
  --dark: #4a2555;
  --light: #fffffc;
  --primary-color: #b131f9;
  --secondary-color: #e94d89;

  --title: 'Libre Franklin', sans-serif;
  --sub-title: 'Mate', serif;
  --text: 'Raleway', sans-serif;
}

body {
  background-color: var(--light);
  color: var(--text-dark);
  margin: 0;
  padding: 0;
}

h1 {
  font-family: var(--title);
}

h2, h3, h4, h5, h6 {
  font-family: var(--sub-title);
}

p, button {
  font-family: var(--text);
}

button {
  cursor: pointer;
  font-size: 18px;
  background-color: var(--primary-color);
  color: var(--light);
  padding: 8px 18px;
  border-radius: 18px;
}

button:disabled {
  background-color: rgb(59, 33, 59);
  cursor: not-allowed;
}